import React from 'react';

import Layout from '../components/Layout';
import Header from '../components/Header';
import Footer from '../components/Footer';
import EventPopup from '../components/EventPopup';
import Seo from '../components/Seo';
import {handleAnalytics} from '../shared/analytics';
import {TRACK_NAVIGATION_YEAR} from '../shared/constants';
import SwipeableDate from '../components/SwipeableDate';

class YearPage extends React.Component {
  componentDidMount() {
    handleAnalytics({event: TRACK_NAVIGATION_YEAR});
  }

  render() {
    return (
      <Layout>
        <Seo />
        <Header interval="year" />
        <SwipeableDate type="year" />
        <Footer />
        <EventPopup />
      </Layout>
    );
  }
}

export default YearPage;
